/**
 * Load environment variables in.
 */
export const API_ENDPOINT: string = process.env.NEXT_PUBLIC_API_ENDPOINT as string

export const AUTH0_DOMAIN: string = process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string
export const AUTH0_CLIENT_ID: string = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string
export const AUTH0_AUDIENCE: string = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE as string
export const AUTH0_RESPONSE_TYPE: string = process.env.NEXT_PUBLIC_AUTH0_RESPONSE_TYPE as string
export const AUTH0_SCOPE: string = process.env.NEXT_PUBLIC_AUTH0_SCOPE as string

export const DD_APPLICATION_ID: string = process.env.NEXT_PUBLIC_DD_APPLICATION_ID as string
export const DD_CLIENT_TOKEN: string = process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN as string
export const DD_SAMPLE_RATE: number = parseInt(process.env.NEXT_PUBLIC_DD_SAMPLE_RATE || '0', 10)
export const DD_SERVICE: string = process.env.NEXT_PUBLIC_DD_SERVICE as string
export const DD_VERSION: string = process.env.NEXT_PUBLIC_DD_VERSION as string
export const DD_ENV: string = process.env.NEXT_PUBLIC_DD_ENV as string

export const NODE_ENV: string = process.env.NODE_ENV as string

export const GOOGLE_ANALYTICS_ID: string = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID as string
