import React, { useEffect } from 'react'

import { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'

import CssBaseline from '@material-ui/core/CssBaseline'
import { StylesProvider } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'

import { Auth0Provider } from 'use-auth0-hooks'
import { DataDogRUMProvider } from '@assuranceiq/react-components'
import NProgress from 'nprogress'
// @ts-ignore
// eslint-disable-next-line
import nprogress from 'nprogress/nprogress.css'

import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_RESPONSE_TYPE,
  AUTH0_SCOPE,
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_ENV,
  DD_SAMPLE_RATE,
  DD_SERVICE,
  DD_VERSION
} from '@/common/environment-variables'
import theme from '@/styles/theme'

import '@/styles/global.scss'

NProgress.configure({
  minimum: 0.3,
  showSpinner: false,
  parent: '#appBar'
})

Router.events.on('routeChangeStart', () => {
  try {
    NProgress.start()
  } catch (ex) {
    // do nothing
  }
})
Router.events.on('routeChangeComplete', () => {
  try {
    NProgress.done()
  } catch (ex) {
    // do nothing
  }
})
Router.events.on('routeChangeError', () => {
  try {
    NProgress.done()
  } catch (ex) {
    // do nothing
  }
})

const onRedirectCallback = appState => {
  // redirect to a page where user was before
  if (appState && appState.returnTo) {
    Router.push({
      pathname: appState.returnTo.pathname,
      query: appState.returnTo.query
    })
  }
}

export default function App(props: AppProps) {
  const { Component, pageProps } = props
  const auth0RedirectUri = typeof window !== 'undefined' ? window.document.location.href : ''

  useEffect(() => {
    // remove the server-side injected CSS
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  return (
    <>
      <Head>
        <title>AMP</title>
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
      </Head>
      <DataDogRUMProvider
        applicationId={DD_APPLICATION_ID}
        clientToken={DD_CLIENT_TOKEN}
        env={DD_ENV}
        sampleRate={DD_SAMPLE_RATE}
        service={DD_SERVICE}
        trackInteractions={true}
        version={DD_VERSION}
      >
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          audience={AUTH0_AUDIENCE}
          responseType={AUTH0_RESPONSE_TYPE}
          scope={AUTH0_SCOPE}
          redirectUri={auth0RedirectUri}
          onRedirectCallback={onRedirectCallback}
          post
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <StylesProvider injectFirst>
              <Component {...pageProps} />
            </StylesProvider>
          </ThemeProvider>
        </Auth0Provider>
      </DataDogRUMProvider>
    </>
  )
}
